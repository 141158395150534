<template>
  <main class="main">
    <div class="container">
      <div class="main__inner">
        <section class="section mycity_reference">
          <h2>Справочные данные</h2>
          <div class="row">
            <div class="col-12">
              <div class="item" v-for="(t, i) in types" :key="i">
                <template v-if="t.reference_data && t.reference_data.length">
                  <div class="title">{{ t.name }}</div>
                  <div class="info__item" v-for="(r, index) in t.reference_data" :key="index">
                    <div class="info__title">{{ r.title }}</div>
                    <div class="info__description">
                      {{ r.phone }}
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </main>
</template>
<script>
export default {
  name: "MyCityReference",
  async asyncData({ store }) {
    await store.dispatch("GET_MYCITY_REF_PAGE");
  },
  computed: {
    types() {
      return this.$store.state.mycity_ref.types;
    },
  },
  metaInfo() {
    return this.$seo(
      "common",
      "Официальный сайт администрации города Махачкалы",
      "",
      "",
      "Официальный сайт администрации города Махачкалы",
      "",
      ""
    );
  },
};
</script>

<style lang="stylus">
@import "~@/styles/parts/mycity/reference/reference.styl"
</style>
